interface ButtonProps {
  className?: string;
  type?: "button" | "submit" | "reset" | "link" | "text";
  [propName: string]: string | any;
}

export default function Button({
  className = "",
  type = "button",
  children,
  ...other
}: ButtonProps): JSX.Element {
  return type === "link" ? (
    <a
      className={`inline-flex justify-center items-center text-primary text-sm cursor-pointer ${className}`}
      {...other}
    >
      {children}
    </a>
  ) : type === "text" ? (
    <span
      className={`inline-flex justify-center items-center text-primary text-sm cursor-pointer ${className}`}
      {...other}
    >
      {children}
    </span>
  ) : (
    <button
      type={type}
      className={`inline-flex justify-center items-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 !transition-colors !duration-300 ${className}`}
      {...other}
    >
      {children}
    </button>
  );
}
