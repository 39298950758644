/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from "next/link";
import { Image } from "@components/common";
import { useTranslation } from "react-i18next";

export default function NavLogo() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-shrink-0 items-center">
      <Link href="/">
        <div className="flex">
          <span className="sr-only">HPC-AI Technology Inc.</span>
          <Image
            className="h-14 w-auto block"
            src={"/logo.svg"}
            alt="platform logo"
          />
        </div>
      </Link>
    </div>
  );
}
