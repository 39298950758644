// import { type MachineType, type Machine } from "@utils/api/resource";
import { type ServerCardProps } from "@components/aiserver/Card";

export const REGION = process.env.REACT_APP_PUBLIC_REGION || "cn";

export const IS_DEV = process.env.REACT_APP_PUBLIC_IS_DEV;

const ENV_NAME = process.env.REACT_APP_ENV_NAME || "default";

interface Env {
  defaultConsoleApp?: string;
  disableApps: string[];
}

const envs: Record<string, Env> = {
  default: {
    defaultConsoleApp: "resource",
    disableApps: [],
  },
};

export const env = envs[ENV_NAME as keyof typeof envs];

export interface BankItem {
  name: string;
  acctIssrId: string;
}

export const dateUnit = {
  perDay: { unit: "days", day: 1 },
  perWeek: { unit: "weeks", day: 7 },
  perMonth: { unit: "months", day: 30 },
  perYear: { unit: "years", day: 365 },
};

export const banks: BankItem[] = [
  { name: "gongshang", acctIssrId: "01020000" },
  { name: "nongye", acctIssrId: "01030000" },
  { name: "zhongguo", acctIssrId: "01040000" },
  { name: "jianshe", acctIssrId: "01050000" },
  { name: "jiaotong", acctIssrId: "03010000" },
  { name: "zhongxin", acctIssrId: "03020000" },
  { name: "guangda", acctIssrId: "03030000" },
  { name: "huaxia", acctIssrId: "03040000" },
  { name: "minsheng", acctIssrId: "03050000" },
  { name: "guangfa", acctIssrId: "03060000" },
  { name: "zhaoshang", acctIssrId: "03080000" },
  { name: "xingye", acctIssrId: "03090000" },
  { name: "pufa", acctIssrId: "03100000" },
  { name: "pingan", acctIssrId: "03134402" },
  { name: "youchu", acctIssrId: "04030000" },
  { name: "beijing", acctIssrId: "03130011" },
  { name: "shanghai", acctIssrId: "03130031" },
  { name: "nanjing", acctIssrId: "03133201" },
  { name: "hangzhou", acctIssrId: "03133301" },
  { name: "zheshang", acctIssrId: "03160000" },
  { name: "shanghainongshang", acctIssrId: "04020031" },
  { name: "lanzhou", acctIssrId: "04478210" },
  { name: "gansu", acctIssrId: "05818200" },
  { name: "guizhou", acctIssrId: "05847000" },
];

export const defaultBalanceInfo = {
  balance: "0.00",
  availableBalance: "0.00",
  freezeAmount: "0.00",
  creditAmount: "0.00",
  unpaidAmount: "0.00",
  availableVoucherAmount: "0.00",
};

export const DEFAULT_CONSOLE_APP = env.defaultConsoleApp || "dataset";

export const moneyCharacter = REGION === "cn" ? "¥" : "$";

// export const mockMachines: MachineType[] = [
//   // {
//   //   gpuName: "NVIDIA-A800",
//   //   totalGPU: 8,
//   //   availableGPU: 0,
//   // },
//   {
//     gpuName: "ASCEND-910B",
//     totalGPU: 8,
//     availableGPU: 0,
//   },
// ];

// export const mockMachineInfos: Machine[] = [
//   // {
//   //   machineName: "a800-170",
//   //   machineId: "10",
//   //   gpu: {
//   //     gpuName: "NVIDIA-A800",
//   //     gpuMemory: 83886080,
//   //     totalGPU: 8,
//   //     availableGPU: 0,
//   //   },
//   //   cpuName: "XEON Gold 8358",
//   //   numberOfCPUPerGpu: 16,
//   //   memoryPerGpu: 134217728,
//   //   diskType: "",
//   //   systemDiskSize: 50,
//   //   dataDiskSize: 0,
//   //   expandableDiskSize: 0,
//   //   gpuDriver: "535.104.05",
//   //   cudaVersion: "12.2",
//   //   price: {
//   //     tidePerHour: 5.99,
//   //     perHour: 5.99,
//   //     perDay: 199,
//   //     perWeek: 1999,
//   //     perMonth: 19999,
//   //     perYear: 199999,
//   //   },
//   //   expireDate: 1725120000000,
//   // },
//   {
//     status: "running",
//     warningLabel: "",
//     warningContent: "",
//     machineName: "910b-175",
//     machineId: "17",
//     gpu: {
//       gpuName: "ASCEND-910B",
//       gpuMemory: 67108864,
//       totalGPU: 8,
//       availableGPU: 0,
//     },
//     cpuName: "Kunpeng 920",
//     numberOfCPUPerGpu: 24,
//     memoryPerGpu: 134217728,
//     diskType: "",
//     systemDiskSize: 50,
//     dataDiskSize: 0,
//     expandableDiskSize: 0,
//     systemFreeDiskSizeGB: 400,
//     dataFreeDiskSizeGB: 300,
//     gpuDriver: "-",
//     cudaVersion: "",
//     price: {
//       tidePerHour: 5.99,
//       perHour: 5.99,
//       perDay: 199,
//       perWeek: 1999,
//       perMonth: 19999,
//       perYear: 199999,
//     },
//     expireDate: 1725120000000,
//   },
// ];

export const durationOptions: Record<string, number[]> = {
  perDay: [1, 2, 3, 4, 5, 6],
  perWeek: [1, 2, 3],
  perMonth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

export const aiServers: ServerCardProps[] = [
  {
    id: "wts",
    tag: "8卡服务器",
    title: "WTS - 智算中心服务器AWT9000",
    desc: "8卡企业级服务器，超大显存，训推一体解决方案",
    imgSrc: "/aiServer.png",
    specs: [
      "2 x Intel至强 Platinum 8480+ 处理器 56核心112线程",
      "16 x 64G DDR5 RDIMM 内存 4800MT/s",
      "2 x 960G SATA SSD",
      "2 x 3.84T NVMe PCIe",
      "8 x NVDIA HGX H20",
      "4 x 400Gb/s IB网卡",
    ],
    price: 1250000,
  },
];

export const rootPathExp = /^\/([^/]+)/;
