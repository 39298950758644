import { type TagInfo } from "@src/utils/api/project";
import { useState, useEffect } from "react";
import { HiMiniXCircle } from "react-icons/hi2";

interface FilterProps {
  tagInfos: TagInfo[] | null;
  onChange(tags: string[]): any;
  value?: string[];
}

export default function TagSelection({
  tagInfos,
  onChange,
  value,
}: FilterProps) {
  const [selectedTags, setSelectedTags] = useState<string[]>(value || []);

  useEffect(() => {
    value && setSelectedTags(value);
  }, [value]);

  const handleSelect = (tag: string, index: number) => {
    const newSelectedTags = [...selectedTags];
    newSelectedTags[index] = tag;

    !value && setSelectedTags(newSelectedTags);
    onChange(newSelectedTags);
  };

  return tagInfos ? (
    <div className="bg-slate-50 py-2 px-3 rounded">
      {tagInfos.map((item, index) => (
        <div className="flex py-2" key={item.name}>
          <div className="border-r-2    border-gray-300 min-w-[144px] justify-between px-2 pt-2">
            {item.name}
          </div>
          <div className="flex-1 ml-3 mt-2 min-h-[34px]">
            {item.tagValues.map((tag) => (
              <div key={tag.value} className="inline-block mr-2 mb-2">
                <div
                  className={`flex items-center border rounded px-2 text-gray-600 cursor-pointer transition-colors ${
                    selectedTags[index] === tag.value
                      ? "bg-[#F0FFFE] "
                      : "bg-white hover:bg-[#F0FFFE]"
                  }`}
                  onClick={() => handleSelect(tag.value, index)}
                >
                  {tag.name}
                  {selectedTags[index] === tag.value && (
                    <HiMiniXCircle
                      className="ml-1 hover:text-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect("", index);
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
}
