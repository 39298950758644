import { classNames } from "@src/utils";

interface InputProps extends Record<string, any> {
  id?: string;
  label?: JSX.Element;
  tip?: JSX.Element;
  rows?: number;
  className?: string;
}

export default function Textarea({
  id,
  label,
  tip,
  rows = 3,
  className,
  ...other
}: InputProps) {
  return (
    <>
      {!!label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <textarea
          id={id}
          rows={rows}
          className={classNames(
            "shadow-sm block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm",
            className
          )}
          {...other}
        />
      </div>
      {!!tip && <p className="mt-2 text-sm text-gray-500">{tip}</p>}
    </>
  );
}
