"use client";
// language select dropdown
import { Fragment, useState, useEffect } from "react";
import { Transition, Listbox } from "@headlessui/react";
import { classNames } from "@src/utils";
import { HiChevronDown } from "react-icons/hi2";

type BasicType = string | number;

export interface DropdownProps {
  placeholder?: string;
  name?: string; // must select one of them (name or value)
  value?: Item | BasicType;
  defaultValue?: Item | BasicType;
  options: (Item | BasicType)[];
  inputClass?: string; // input tailwind class name
  optionsClass?: string; // options tailwind class name
  onChange?: (value: BasicType | Item) => any;
}

export interface Item extends Record<string, any> {
  name: string;
  value: BasicType;
}

export default function Dropdown({
  placeholder = " ",
  name,
  value = "",
  defaultValue,
  options,
  inputClass,
  optionsClass,
  onChange,
}: DropdownProps) {
  const [localVal, setLocalVal] = useState<Item | BasicType>(
    defaultValue || ""
  );

  useEffect(() => {
    setLocalVal(value);
  }, [value]);

  useEffect(() => {
    !value && defaultValue && setLocalVal(defaultValue);
  }, [defaultValue, value]);

  const handleChange = (val: BasicType | Item) => {
    setLocalVal(val);
    onChange && onChange(val);
  };

  return value || placeholder ? (
    <Listbox
      name={name}
      value={typeof localVal === "object" ? localVal?.value || "" : localVal}
      onChange={handleChange}
    >
      {({ open }) => (
        <div className="relative w-fit inline-block">
          <Listbox.Button
            className={`${
              localVal ? "text-gray-900" : "text-gray-500"
            } font-medium inline-flex items-center ${inputClass}`}
          >
            <span className="block truncate flex-1 text-start">
              {(typeof localVal === "object" ? localVal?.name : localVal) ||
                placeholder}
            </span>
            <HiChevronDown
              className={classNames(
                open ? "text-gray-600" : "text-gray-400",
                "ml-2 h-5 w-5 group-hover:text-gray-500"
              )}
              aria-hidden="true"
            />
          </Listbox.Button>

          {!!options.length && (
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`min-w-fit absolute right-0 z-10 mt-1 max-h-60 w-25 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionsClass}`}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={
                      typeof option === "object"
                        ? String(option.value)
                        : String(option)
                    }
                    className={({ active }) =>
                      `${
                        active ? "text-gray-900 bg-gray-100" : "text-gray-700"
                      } ${
                        (
                          typeof option === "object"
                            ? option.value === (value as Item).value
                            : option === value
                        )
                          ? "font-semibold bg-[#F0FFFE]"
                          : "font-normal"
                      } cursor-pointer relative select-none py-2 pl-3 pr-9 transition-colors block truncate`
                    }
                    value={option}
                  >
                    {typeof option === "object" ? option.name : option}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          )}
        </div>
      )}
    </Listbox>
  ) : (
    <></>
  );
}
