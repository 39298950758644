import Tooltip, { type TooltipProps } from "./Tooltip";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";

export interface QuestionTooltipProps {
  title: string | React.ReactNode;
  position?: TooltipProps["position"];
  className?: string;
  containerClassName?: string;
}

const QuestionTooltip = ({
  title,
  position = "top",
  className = "",
  containerClassName = "",
}: QuestionTooltipProps) => {
  return (
    <Tooltip
      content={title}
      position={position}
      className={className}
      containerClassName={containerClassName}
    >
      <HiOutlineQuestionMarkCircle
        data-testid="hyper-param-desc"
        className="mx-1 h-5 w-5 text-[#999999]"
      />
    </Tooltip>
  );
};

export default QuestionTooltip;
