import { useState, useEffect } from "react";

interface InputProps extends Record<string, any> {
  id?: string;
  label?: JSX.Element | string;
  name: string;
  className?: string;
  defaultChecked: boolean;
}

export default function Checkbox({
  id,
  label,
  name = "",
  className,
  defaultChecked = false,
  ...other
}: InputProps) {
  const [checked, setChecked] = useState(defaultChecked);

  const handleClick = () => {
    const newVal = !checked;
    setChecked(newVal);
  };
  // listen defaultChecked's change, because it may be from api request
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div className={`flex items-center ${className || ""}`}>
      {/* handle interaction */}
      <input
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary cursor-pointer"
        onClick={handleClick}
        {...(checked ? { checked: true } : null)}
        {...other}
        name={name}
        value={checked ? "checked" : ""}
      />
      {/* hidden data */}
      {/* <input
        readOnly
        className="hidden"
        id={id}
        name={name}
        value={checked ? "checked" : ""}
      ></input> */}
      {!!label && (
        <label htmlFor={id} className="ml-2 block text-sm text-gray-900">
          {label}
        </label>
      )}
    </div>
  );
}
