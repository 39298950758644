import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames } from "@src/utils";
import { HiOutlineChevronDown, HiCheck } from "react-icons/hi2";

interface ObjectItem {
  id: string | number;
  name: string;
}

type Item = ObjectItem | ObjectItem["id"];
type ItemAttributeKey = "id" | "name";

function getItemAttribute(item: Item, key: ItemAttributeKey) {
  if (typeof item === "string" || typeof item === "number") {
    return item;
  } else if (typeof item === "object") {
    return item[key];
  } else {
    console.error(new TypeError(`Unsupported item type: ${typeof item}`));
  }
}
function getItemName(item: Item | undefined | null) {
  if (!item) return "";
  return getItemAttribute(item, "name");
}

function getItemId(item: Item | undefined | null) {
  if (!item) return "";
  return getItemAttribute(item, "id");
}

interface SelectProps {
  label: string;
  name: string;
  items: Item[];
  defaultValue?: Item;
  onChange?(value: Item): any;
}

export default function Select({
  label,
  name,
  items,
  defaultValue, // if the default value is fetched async, defaultValue is required
  onChange,
}: SelectProps) {
  const [value, setValue] = useState<Item>(defaultValue || items[0] || "");
  useEffect(() => {
    setValue(defaultValue || items[0]);
  }, [items, defaultValue]);

  const handleChange = (item: Item) => {
    setValue(item);
    onChange && onChange(item);
  };

  return (
    // if the default value is fetched async, this component needs attribute "key" to rerender
    <Listbox
      name={name}
      onChange={handleChange}
      value={getItemId(value)}
      key={getItemId(defaultValue)}
    >
      <Listbox.Label className="block text-sm font-medium text-gray-700">
        {label}
      </Listbox.Label>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm min-h-[38px]">
          <>
            <span className="block truncate whitespace-pre-line">
              {getItemName(value)}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiOutlineChevronDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </>
        </Listbox.Button>

        {!!items.length && (
          <Transition
            as={Fragment}
            leave="transition duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.map((item) => (
                <Listbox.Option
                  key={getItemId(item)}
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-primary" : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-8 pr-4"
                    )
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-semibold !pr-5" : "font-normal",
                          "block truncate whitespace-pre-line"
                        )}
                      >
                        {getItemName(item)}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-primary",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        )}
      </div>
    </Listbox>
  );
}
