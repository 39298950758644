// import { REGION } from "@src/constant";
import { DialogContainer, Button, Image } from "@components/common";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@src/store";
import { openContactDialog } from "@src/store/common";

const questionnaireLink = "https://wj.qq.com/s2/14488320/rlq7/";
// const slackJoinLink =
//   "https://join.slack.com/t/colossalaiworkspace/shared_invite/zt-2404o93sy-Y3~br1qkIeEcMOVSfJ8YYg";
// const slackLink = "https://colossalaiworkspace.slack.com/team/U02NCSGFD52";

export default function ContactDialog() {
  const { t } = useTranslation();

  const open = useAppSelector((state) => state.common.isContactDialogOpen);
  const dispatch = useAppDispatch();

  return (
    <DialogContainer
      className="max-h-fit"
      open={open}
      setOpen={(isOpen) => dispatch(openContactDialog(isOpen))}
    >
      <div className="font-mono min-h-[300px] w-[730px] pb-6">
        <div className="mb-4 text-xl font-semibold">
          {t("home.footer.labels.contact")}
        </div>
        <div className="text-gray-500 mb-8">{t("common.contact.tip")}</div>

        <div className="mb-4 text-xl font-semibold">联系电话：</div>

        <span className="mr-8">18916389502</span>
        <div className="mt-8 mb-4 text-xl font-semibold">
          {t("account.common.email")}：
        </div>
        <span>wutongshu@wtsht.cn</span>
      </div>
    </DialogContainer>
  );
}
