import type { AppProps /*, AppContext */ } from "next/app";
import "@src/global.css";
import { Provider } from "react-redux";
import store from "@src/store";
import { ContactDialog, ConsoleLayout } from "@components/common";
import { NavBar, Notifications } from "@components/common";
import { useRouter } from "next/router";
import React, { type ReactNode } from "react";
import AccountLayout from "@components/account/AccountLayout";
import AiServerLayout from "@components/aiserver/AiServerLayout";
import BillingLayout from "@components/billing/BillingLayout";
import AdminLayout from "@components/admin/AdminLayout";
import { rootPathExp } from "@src/constant";
import Head from "next/head";

const layouts: Record<string, any> = {
  account: AccountLayout,
  console: ConsoleLayout,
  aiserver: AiServerLayout,
  billing: BillingLayout,
  admin: AdminLayout,
};
export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const rootPath: string = router.pathname.match(rootPathExp)?.[1] || "";
  const CurLayout =
    layouts[rootPath] ||
    (({ children }: { children: ReactNode }) => <>{children}</>);

  return (
    <>
      <Head>
        <title>武健算网 | 省钱、弹性、稳定 | 租GPU算力</title>
      </Head>
      <Provider store={store}>
        <NavBar />
        <CurLayout>
          <Component {...pageProps} />
        </CurLayout>
        <ContactDialog />
        <Notifications />
      </Provider>
    </>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }
